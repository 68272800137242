<template>
  <div
    class="ui-avatar ui-noselect"
    :style="style"
    :title="title"
  >
    <span v-if="!src || isError">{{ initials }}</span>
    <img
      style="display:none"
      v-if="src"
      :src="src"
      @error="isError = true"
    />
  </div>
</template>

<script>
export default {
  name: 'ui-avatar',

  props: {
    title: {
      type: String,
      required: true
    },

    src: {
      type: String,
      default: null
    },

    size: {
      type: [Number, String],
      default: 42
    }
  },

  data() {
    return {
      colors: [
        '#3c6478',
        '#43abc9',
        '#b5c689',
        '#bca136',
        '#c2571a',
        '#902617'
      ],
      isError: false
    };
  },

  computed: {
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        fontSize: Math.round(this.size * 0.4) + 'px',

        backgroundImage:
          this.src && !this.isError ? `url('${this.src}')` : undefined,

        backgroundColor: this.colors[
          this.stringToNumber(this.title) % this.colors.length
        ]
      };
    },

    initials() {
      let words = this.title.split(' ');
      let initials = '';

      words.forEach(word => {
        if (initials.length < 2) {
          initials = initials + word.charAt(0).toUpperCase();
        }
      });

      return initials;
    }
  },

  methods: {
    stringToNumber(string) {
      if (!string) {
        return 0;
      }

      let output = 0;
      for (let i = 0; i < string.length; i++) {
        output += string[i].charCodeAt(0);
      }
      return output;
    }
  }
};
</script>

<style lang="scss">
.ui-avatar {
  display: inline-block;
  position: relative;

  overflow: hidden;
  border-radius: 50%;
  color: #fff;

  background-size: cover;
  background-position: 50% 0;

  & > span {
    display: block;
    text-align: center;
    position: relative;
    top: 23%;
  }
}
</style>